import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Yead from "../assets/img/Yead.png" 
import Tashfiq from "../assets/img/Tashfiq.png" 
import Juel from "../assets/img/Juel.png" 
import Rony from "../assets/img/Rony.png" 

export const Contact = () => {
  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Get In Touch</h2>
                  <Row>
                    <Col size={12} sm={12} className="px-1 contcol">
                      <Row>
                        <Col sm={6} className="conimgcol">
                          <a href='https://www.facebook.com/riyazhasan.juel' target='_blank' rel="noopener noreferrer"><img src={Juel} alt="Juel" className="contimg"/></a>
                        </Col>
                        <Col sm={6}>
                          <h1>Juel Hasan Riyaj</h1>
                          <h5>Chairman, CEO</h5>
                        </Col>
                      </Row>
                    </Col>
                    <Col size={12} sm={12} className="px-1 contcol">
                      <Row>
                        <Col sm={6} className="conimgcol">
                          <a href='https://www.facebook.com/profile.php?id=100009594543578' target='_blank' rel="noopener noreferrer"><img src={Tashfiq} alt="Tashfiq" className="contimg"/></a>
                        </Col>
                        <Col sm={6}>
                         <h1>Tashfiqul Ahamed Bhor</h1>
                          <h5>Executive Director, CMO</h5>
                        </Col>
                      </Row>
                    </Col>
                    <Col size={12} sm={12} className="px-1 contcol">
                      <Row>
                        <Col sm={6} className="conimgcol">
                          <a href='https://www.facebook.com/ekrabinsyedmujib.yead/' target='_blank' rel="noopener noreferrer"><img src={Yead} alt="Yead" className="contimg"/></a>
                        </Col>
                        <Col sm={6}>
                          <h1>Syed Yead</h1>
                          <h5>Managing Director, CTO</h5>
                        </Col>
                      </Row>
                    </Col>
                    <Col size={12} sm={12} className="px-1 contcol">
                      <Row>
                        <Col sm={6} className="conimgcol">
                          <a href='https://www.facebook.com/M.A.KawsarAhmed94' target='_blank' rel="noopener noreferrer"><img src={Rony} alt="Rony" className="contimg"/></a>
                        </Col>
                        <Col sm={6}>
                          <h1>Kawsar A. Rony</h1>
                          <h5>Treasurer, CAO</h5>
                        </Col>
                      </Row> 
                    </Col>
                  </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

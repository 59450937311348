import { Container, Row, Col } from "react-bootstrap";
import navIcon2 from "../assets/img/nav-icon2.svg";
import JBY_logo from "../assets/img/jby logo.png"
import youtubeicon from '../assets/img/youtube.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={JBY_logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {/* <a href="#"><img src={navIcon1} alt="Icon" /></a> */}
              <a href="https://www.facebook.com/profile.php?id=61550322268138" target='_blank' rel="noreferrer"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://www.youtube.com/channel/UCOP1pw66EkOS5ia4yDG-ZRw" target='_blank' rel="noreferrer"><img src={youtubeicon} alt="Icon" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

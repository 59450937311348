// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route,BrowserRouter, Routes } from 'react-router-dom';
import Admin from './components/admin';
import Home from './components/home';
import Sessions from './components/Sessions';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/admin" element={<Admin />}/>
          <Route path="/session" element={<Sessions />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

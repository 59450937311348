import { NavBar } from "./NavBar";
import { Banner } from "./Banner";
import { HR } from "./Hrs";
import { Cars } from "./Cars";
import { Contact } from "./Contact";
import { Footer } from "./Footer";

export default function Home(){
    return(
        <div>
            <NavBar />
            <Banner />
            <HR />
            <Cars />
            <Contact />
            <Footer />
        </div>
    )
}
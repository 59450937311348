import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import school1 from "../assets/img/school-1.png"
import colorSharp from "../assets/img/color-sharp.png"
import aishinLogo from "../assets/img/asian_logo.png"

export const HR = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const SchoolList = [{
    "name": "School1",
    "image":school1
  }
]
let num = SchoolList.length

const SchoolRow = () =>{
  let row = []
  for (let i = 0 ; i < num ; i++){
    row.push(
    <div className="item">
        <img src={SchoolList[i].image} alt={i} className='scimg'/>
        <h5>{SchoolList[i].name}</h5>
    </div>
    )}

  return row
}

  return (
    <section className="skill" id="hr">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>HR</h2>
                        {/* <button className="vvd scbut"><span>Aishin Education</span></button> */}
                        <div className="item">
                          <a href='/session'><img src={aishinLogo} alt="aishin" className='scimg'/></a>
                          <h4>Aishin Education</h4>
                          <p>Our Schooling Partner</p>
                      </div>
                        <p>We have connection to a number of leading language schools in Japan. Some of the iconic ones are:</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                          {SchoolRow()}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="backimg" />
    </section>
  )
}

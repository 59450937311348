import { useState } from "react"
import { NavBar } from "./NavBar"
import n5 from "./N5.json"
import n4 from "./N4.json"
import n3 from "./N3.json"
import aishinLogo from "../assets/img/asian_logo.png"

export default function Sessions(){
    const [isExpanded5, setIsExpanded5] = useState(false);
    const [isExpanded4, setIsExpanded4] = useState(false);
    const [isExpanded3, setIsExpanded3] = useState(false);
    
    const toggleExpand5 = () => {
        setIsExpanded5(!isExpanded5);
    };
    const toggleExpand4 = () => {
        setIsExpanded4(!isExpanded4);
    };
    const toggleExpand3 = () => {
        setIsExpanded3(!isExpanded3);
    };
    const itemList = (iteams) => iteams.map((item)=>{
        return(
            <>
            <h3>{item.title}</h3>
                <h6>{item.time}</h6>
            <ul>
                <li>{item.description}</li>
                <li>{item.book}</li>
                <li>{item.sdate}</li>
                <li>{item.edate}</li>
                <li>{item.days}</li>
                <li>{item.hours}</li>
                <li>{item.classes}</li>
                <li>{item.weeklyhours}</li>
                <li>{item.musthours}</li>
                <li>{item.holi}</li>
            </ul>
        </>)
    })
    return(
        <>
            <NavBar/>
            <p className="clist">
                <img src={aishinLogo} alt="aishin" className='scimg'/>
                <h2 className="sah">Aishin Education</h2>
                <h4 className="sah">Educational Partner of JBY Unity</h4>
                <h6 className="sah">Please checkout the courses we offer bellow</h6>
                <button class="button-30" onClick={toggleExpand5} role="button">
                    {isExpanded5 ? 'Collapse' : 'N5 Course List'}
                </button>
                    {isExpanded5 ? itemList(n5) : null}
                
                    <button class="button-30" onClick={toggleExpand4} role="button">
                    {isExpanded4 ? 'Collapse' : 'N4 Course List'}
                </button>
                    {isExpanded4 ? itemList(n4) : null}
                
                    <button class="button-30" onClick={toggleExpand3} role="button">
                    {isExpanded3 ? 'Collapse' : 'N3 Course List'}
                </button>
                    {isExpanded3 ? itemList(n3) : null}

            </p>
        </>
    )
}

import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import tp17 from "../assets/img/Toyota_Premio_2017.png"
import ta17 from "../assets/img/Toyota_Axio_2017.png"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Cars = () => {

  const cars = [
    {
      title: "Toyota Premio",
      description: "2018 model of toyota premio starting from 200 man yen.",
      imgUrl: tp17,
    },
    {
      title: "Toyota Axio",
      description: "2018 model of toyota axio starting from 140 man yen",
      imgUrl: ta17,
    },
  ];

  return (
    <section className="project" id="car">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Car/Car Parts</h2>
                <p>With us, you can get your car business a new boost. We will help you buying cars, car parts and any other car accessories directly from Japan. You can have new, recondition or second hand. Just name it and we will send it to you in the shortest time.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <p>We have a large collection of cars. If you want any other car, then you can contact with us. We will be able to provide any car possible.</p>
                      <Row>
                        {
                          cars.map((car, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...car}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>We can also provide any car parts that are available in Japan. Please contact us if you need any car parts.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="colorSharp2"></img>
    </section>
  )
}
